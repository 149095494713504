import {Button, Table, TableCell, TableHead, TableRow, TextField, Typography} from "@mui/material";
import MasterPalletService from "./MasterPalletService";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {LoadingComponent} from "../../components/loading/LoadingComponent";
import moment from "moment";
import TripService from "../trips/TripService";
import {CircularProgress} from "@material-ui/core";
import {Autorenew} from "@material-ui/icons";
import StyledButton from "../../components/buttons/StyledButton";
import DepartureService from "../check_in_departures/DepartureService";
import Notification from "../../components/notifications/Notification";


export default function MasterPalletDetails(props) {

    const {t} = useTranslation();

    const regexp = /^[0-9]*$|^NULL$/

    const warehouses = props.trip.warehouses;

    const [types, setTypes] = useState([]);
    const [data, setData] = useState({});

    const [loading, setLoading] = useState(false);

    async function getMasterPalletTypes() {

        setLoading(true);

        await new MasterPalletService().getMasterPalletTypes()
            .then(response => {
                setTypes(response);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            })

    }

    function onQuantityChange(warehouseIndex, masterPalletIndex) {
        return (event) => {
            if (regexp.test(event.target.value)) {
                const newData = {...data};

                let value = isNaN(event.target.valueAsNumber) ? null : event.target.valueAsNumber

                if (!value) {
                    value = 0;
                }

                newData.warehouses[warehouseIndex].masterPallets[masterPalletIndex].quantity = value;

                setData(newData);
                props.setMasterPallets(newData);
            }
        };
    }

    useEffect(() => {
        getMasterPalletTypes();
    }, []);

    useEffect(() => {

        let newData = {};
        newData.warehouses = [];

        warehouses?.map(warehouse => {

            let warehouseData = {warehouse: warehouse, masterPallets: []};

            types.map(type => {
                let masterPallet = {type: type.id, quantity: 0}

                warehouseData.masterPallets.push(masterPallet);
            })

            newData.warehouses?.push(warehouseData);
        })

        setData(newData);

    }, [types]);


    return <div>
        {loading ?
            <div style={{minWidth: 600}}>
                <LoadingComponent/>
            </div>
            :
            <div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                            {types.map(item => {
                                return <TableCell align={"center"}>
                                    {item.code}
                                </TableCell>
                            })}
                        </TableRow>
                    </TableHead>
                    {data?.warehouses?.map((warehouse, warehouseIndex) => {
                        return <TableRow>
                            <TableCell>
                                <Typography>{warehouse.warehouse.code}</Typography>
                            </TableCell>
                            {warehouse?.masterPallets?.map((masterPallet, masterPalletIndex) => {
                                return <TableCell>
                                    <TextField
                                        type={"number"}
                                        variant={"outlined"}
                                        value={masterPallet.quantity}
                                        onChange={onQuantityChange(warehouseIndex, masterPalletIndex)}
                                    >

                                    </TextField>
                                </TableCell>
                            })}
                        </TableRow>
                    })}
                </Table>
                <div style={{
                    display: "flex",
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBlock: "4%",
                    paddingBottom: "2%"
                }}>
                    <StyledButton variant="contained"
                            onClick={() => {
                                props.setOpen(false)
                            }}
                            style={{margin: "auto"}}>
                        {t("cancel")}
                    </StyledButton>
                    <StyledButton
                        style={{
                            margin: "auto"
                        }}
                        endIcon={<Autorenew/>}
                        onClick={props.confirm}
                        color="primary"
                        variant={"contained"}>
                        {t("generate_summary_out")}
                    </StyledButton>
                </div>
            </div>
        }
    </div>


}